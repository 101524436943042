/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"

const featuredWhite = (
  <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2944 10.1098L16.7636 4.43106L13.2329 10.1098C12.6752 11.0068 11.7891 11.6505 10.7637 11.9038L4.27185 13.5069L8.58155 18.6196C9.26233 19.4273 9.60076 20.4688 9.52471 21.5224L9.04329 28.1919L15.2376 25.673C16.2161 25.2751 17.3112 25.2751 18.2897 25.673L24.484 28.1919L24.0026 21.5224C23.9265 20.4688 24.2649 19.4273 24.9457 18.6196L29.2554 13.5069L22.7636 11.9038C21.7381 11.6505 20.8521 11.0068 20.2944 10.1098ZM7.2422 21.3576C7.27529 20.8992 7.12803 20.446 6.83181 20.0946L0.733323 12.8597C0.46452 12.5408 0.623817 12.0506 1.02872 11.9506L10.215 9.68205C10.6612 9.57186 11.0468 9.29176 11.2895 8.90144L16.2857 0.865739C16.5059 0.51155 17.0214 0.511552 17.2416 0.865738L22.2378 8.90144C22.4805 9.29175 22.866 9.57186 23.3122 9.68205L32.4986 11.9506C32.9035 12.0506 33.0627 12.5408 32.7939 12.8597L26.6955 20.0946C26.3992 20.446 26.252 20.8992 26.2851 21.3576L26.9663 30.7953C26.9963 31.2113 26.5793 31.5143 26.193 31.3572L17.4277 27.7929C17.0019 27.6198 16.5254 27.6198 16.0996 27.7929L7.33432 31.3572C6.94797 31.5143 6.53093 31.2113 6.56096 30.7953L7.2422 21.3576Z"
      fill="white"
    />
  </svg>
)

export default featuredWhite
