import art_white from "./art-white"
import art from "./art"
import blogs_white from "./blogs-white"
import blogs from "./blogs"
import businesses_white from "./business-white"
import businesses from "./business"
import commerce_white from "./buyBlock-white"
import commerce from "./buyBlock"
import design_white from "./design-white"
import design from "./design"
import empower_white from "./empower-white"
import empower from "./empower"
import fashion_white from "./fashion-white"
import fashion from "./fashion"
import featured_white from "./featured-white"
import featured from "./featured"
import funny_white from "./funny-white"
import funny from "./funny"
import lifestyle_white from "./lifestyle-white"
import lifestyle from "./lifestyle"
import music_white from "./music-white"
import music from "./music"
import photo_white from "./photo-white"
import photo from "./photo"
import random_white from "./random-white"
import random from "./random"
import stores_white from "./stores-white"
import stores from "./stores"
import writing_white from "./writing-white"
import writing from "./writing"

const icons: { [index: string]: JSX.Element } = {
  art_white,
  art,
  blogs_white,
  blogs,
  businesses_white,
  businesses,
  buyBlock_white: commerce_white,
  buyBlock: commerce,
  design_white,
  design,
  empower_white,
  empower,
  fashion_white,
  fashion,
  featured_white,
  featured,
  funny_white,
  funny,
  lifestyle_white,
  lifestyle,
  music_white,
  music,
  photo_white,
  photo,
  random_white,
  random,
  stores_white,
  stores,
  writing_white,
  writing,
}

export default icons
