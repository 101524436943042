/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"
import { IThumbnails } from "../fetchSites"
import siteGridStyles from "../styles/siteGridStyles"
import SiteCard from "./SiteCard"

interface IProps {
  sitesData: {
    urls: string[]
    thumbnails: IThumbnails
  }[]
  tag: string
}

const SiteGrid = (props: IProps) => {
  return (
    <div css={siteGridStyles.grid}>
      {props.sitesData.map((siteData) => {
        return <SiteCard key={siteData.urls[0]} tag={props.tag} data={siteData} />
      })}
    </div>
  )
}

export default SiteGrid
