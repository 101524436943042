/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"

const empowerment = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.4076 1.53603C26.4161 1.05532 25.2744 1.14545 24.4031 1.80644L20.0466 5.05128C17.4928 6.9441 14.3381 7.99567 11.1534 7.99567H5.08431C3.49194 7.99567 2.20001 9.28759 2.20001 10.88V17.1593C2.20001 18.7517 3.49194 20.0436 5.08431 20.0436H5.38476L6.887 28.7867C6.97714 29.3575 7.4879 29.7782 8.05875 29.7782H13.0762C13.4368 29.7782 13.7673 29.6279 13.9776 29.3575C14.2179 29.0871 14.3081 28.7266 14.248 28.3961L12.8359 20.1939C15.4197 20.4943 17.9435 21.4557 20.0466 23.0181L24.4031 26.2629C24.9139 26.6235 25.5148 26.8338 26.1157 26.8338C26.5664 26.8338 27.017 26.7436 27.4076 26.5333C28.3991 26.0526 29 25.0611 29 23.9495V4.11989C29 3.03827 28.3991 2.04679 27.4076 1.53603ZM4.57355 17.1894V10.88C4.57355 10.5795 4.81391 10.3692 5.08431 10.3692H11.1233C11.9045 10.3692 12.6857 10.3091 13.4668 10.1889V17.8504C12.6857 17.7302 11.9045 17.6701 11.1233 17.6701H5.08431C4.81391 17.7001 4.57355 17.4598 4.57355 17.1894ZM9.05023 27.4046L7.78835 20.0737H10.4022L11.6641 27.4046H9.05023ZM26.6264 23.9194C26.6264 24.1898 26.4462 24.34 26.326 24.3701C26.2058 24.4302 25.9955 24.4903 25.7852 24.31L21.4587 21.0952C19.7762 19.8333 17.8834 18.9019 15.8704 18.3311V9.70822C17.8834 9.13737 19.7762 8.20598 21.4587 6.9441L25.8152 3.69926C26.0255 3.51899 26.2659 3.60912 26.356 3.63917C26.4762 3.69926 26.6565 3.81944 26.6565 4.08984V23.9194H26.6264Z"
      fill="white"
    />
  </svg>
)

export default empowerment
