/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"

const writing = (
  <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.088 12.6587L21.332 21.4147L18.5427 18.6253L27.1667 10.0013C27.608 9.56 28.192 9.33333 28.7573 9.33333C29.2187 9.33333 29.6653 9.484 30.0093 9.79467C30.4187 10.1627 30.652 10.6667 30.6667 11.212C30.6787 11.7587 30.4747 12.272 30.088 12.6587V12.6587ZM17.1187 20.9733L18.984 22.8386L16.4987 23.46L17.1187 20.9733ZM29.3333 25.3333C29.3333 27.5387 27.5387 29.3333 25.3333 29.3333H6.66667C4.46 29.3333 2.66667 27.5387 2.66667 25.3333V6.66667C2.66667 4.46133 4.46 2.66667 6.66667 2.66667H25.3333C27.5387 2.66667 29.3333 4.46133 29.3333 6.66667V6.69867C27.908 6.52133 26.3973 6.99733 25.2813 8.116L15.7147 17.6827C15.2613 18.1333 14.94 18.7027 14.7827 19.3267L13.3733 24.968C13.2587 25.4227 13.3933 25.904 13.724 26.2347C13.976 26.4867 14.3173 26.6253 14.6667 26.6253C14.7747 26.6253 14.8827 26.612 14.9893 26.584L20.6333 25.1747C21.2573 25.0187 21.8253 24.6947 22.2747 24.2427L29.3333 17.1853V25.3333ZM33.332 11.144C33.3 9.96 32.8173 8.872 31.9933 8.03067C31.9933 8.02 32 8.012 32 8V6.66667C32 2.99067 29.008 0 25.3333 0H6.66667C2.99067 0 0 2.99067 0 6.66667V25.3333C0 29.0093 2.99067 32 6.66667 32H25.3333C29.008 32 32 29.0093 32 25.3333V14.6667C32 14.6227 31.9787 14.5853 31.9747 14.5427C32.8693 13.648 33.364 12.4093 33.332 11.144V11.144Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99947 9.33317H19.9995C20.7368 9.33317 21.3328 8.73717 21.3328 7.99984C21.3328 7.2625 20.7368 6.6665 19.9995 6.6665H7.99947C7.26214 6.6665 6.66614 7.2625 6.66614 7.99984C6.66614 8.73717 7.26214 9.33317 7.99947 9.33317"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6661 14.6668C14.6661 13.9295 14.0701 13.3335 13.3328 13.3335H7.99947C7.26214 13.3335 6.66614 13.9295 6.66614 14.6668C6.66614 15.4042 7.26214 16.0002 7.99947 16.0002H13.3328C14.0701 16.0002 14.6661 15.4042 14.6661 14.6668"
      fill="black"
    />
  </svg>
)

export default writing
