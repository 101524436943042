import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { useEffect } from "react"

function useBodyLock(shouldLock: boolean, allowScrollIn: string) {
  useEffect(() => {
    const scrollableElement = document.getElementById(allowScrollIn)
    if (scrollableElement) {
      if (shouldLock) {
        disableBodyScroll(scrollableElement)
      } else {
        enableBodyScroll(scrollableElement)
        // returns cleanup function
        return () => {
          disableBodyScroll(scrollableElement)
        }
      }
    }
  }, [shouldLock, allowScrollIn]) // Only re-run if shouldLock changes
}

export default useBodyLock
