/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"

const universe = (
  <svg width="705" height="70" viewBox="0 0 705 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M265.639 119.317H246.221V190.123H265.639V119.317Z" fill="white" />
    <path
      d="M255.98 92.0053C254.508 91.9703 253.044 92.2261 251.671 92.7581C250.299 93.2901 249.045 94.0878 247.981 95.1055C246.925 96.1019 246.092 97.31 245.535 98.6508C244.979 99.9916 244.712 101.435 244.751 102.886C244.715 104.347 244.984 105.8 245.54 107.151C246.096 108.503 246.927 109.724 247.981 110.737C249.033 111.776 250.283 112.592 251.658 113.137C253.032 113.681 254.502 113.943 255.98 113.907C257.459 113.954 258.932 113.698 260.308 113.152C261.684 112.607 262.934 111.785 263.979 110.737C265.015 109.714 265.83 108.489 266.376 107.139C266.921 105.789 267.184 104.342 267.149 102.886C267.187 101.44 266.926 100.002 266.38 98.6632C265.834 97.3239 265.017 96.1125 263.979 95.1055C262.921 94.0791 261.668 93.2758 260.294 92.7432C258.92 92.2106 257.453 91.9597 255.98 92.0053V92.0053Z"
      fill="white"
    />
    <path
      d="M466.066 169.491C464.617 169.446 463.173 169.687 461.817 170.202C460.461 170.717 459.221 171.495 458.167 172.491C457.12 173.468 456.293 174.656 455.739 175.977C455.186 177.297 454.92 178.721 454.957 180.152C454.924 181.584 455.188 183.007 455.734 184.331C456.28 185.654 457.095 186.85 458.127 187.843C459.166 188.867 460.401 189.671 461.759 190.205C463.116 190.74 464.568 190.994 466.026 190.953C467.475 190.998 468.917 190.746 470.264 190.211C471.61 189.676 472.833 188.87 473.855 187.843C474.871 186.841 475.67 185.642 476.203 184.319C476.736 182.996 476.992 181.578 476.955 180.152C476.996 178.736 476.741 177.327 476.207 176.015C475.674 174.703 474.873 173.517 473.855 172.531C472.827 171.529 471.607 170.743 470.269 170.22C468.931 169.698 467.502 169.45 466.066 169.491V169.491Z"
      fill="white"
    />
    <path
      d="M137.533 149.62C137.533 157.434 135.997 163.314 132.924 167.261C129.851 171.208 125.051 173.181 118.526 173.181C112.006 173.181 107.233 171.208 104.207 167.261C101.181 163.314 99.6643 157.434 99.6577 149.62V95.6155H79V148.52C79 162.301 82.353 172.818 89.0589 180.072C95.7648 187.326 105.587 190.953 118.526 190.953C131.471 190.953 141.32 187.326 148.072 180.072C154.825 172.818 158.198 162.301 158.191 148.52V95.6555H137.533V149.62Z"
      fill="white"
    />
    <path
      d="M208.345 118.497C203.876 118.351 199.432 119.219 195.347 121.037C191.863 122.716 188.791 125.14 186.348 128.138L184.558 119.317H167.72V190.123H187.138V153.9C187.138 147.74 188.514 142.963 191.267 139.569C194.02 136.175 197.927 134.475 202.986 134.469C212.538 134.469 217.314 140.576 217.314 152.79V190.123H236.732V151.69C236.732 139.849 234.066 131.355 228.733 126.208C223.4 121.061 216.604 118.491 208.345 118.497Z"
      fill="white"
    />
    <path
      d="M327.952 119.317L311.154 170.291L294.065 119.317H273.278L298.755 190.123H322.852L348.339 119.317H327.952Z"
      fill="white"
    />
    <path
      d="M402.893 122.698C397.01 119.804 390.52 118.364 383.965 118.497C377.455 118.37 371.022 119.924 365.287 123.008C359.974 125.936 355.623 130.34 352.759 135.689C349.661 141.609 348.12 148.22 348.279 154.9C348.13 161.555 349.722 168.132 352.899 173.982C355.94 179.414 360.546 183.805 366.117 186.583C371.857 189.523 378.673 190.993 386.565 190.993C392.11 191.1 397.611 189.994 402.683 187.753C407.13 185.771 411.009 182.703 413.962 178.832C416.706 175.221 418.529 170.995 419.271 166.521H400.113C399.383 169.455 397.573 172.005 395.044 173.662C392.08 175.454 388.657 176.341 385.195 176.212C380.236 176.212 376.333 174.742 373.486 171.801C370.64 168.861 368.974 164.821 368.487 159.68V159.13H419.861C420.251 156.949 420.435 154.736 420.411 152.52C420.476 146.201 418.835 139.982 415.662 134.519C412.605 129.453 408.178 125.355 402.893 122.698V122.698ZM368.967 146.459C369.364 142.635 371.215 139.108 374.136 136.609C377.131 134.162 380.91 132.883 384.775 133.008C388.74 132.85 392.634 134.093 395.774 136.519C398.767 139.042 400.666 142.626 401.073 146.519L368.967 146.459Z"
      fill="white"
    />
    <path
      d="M473.085 136.949V119.317H469.786C464.546 119.317 460.367 120.214 457.247 122.008C453.994 123.93 451.291 126.658 449.398 129.928L447.608 119.317H429.96V190.123H449.388V155.41C449.388 149.723 450.675 145.223 453.248 141.909C455.821 138.596 459.857 136.929 465.356 136.909L473.085 136.949Z"
      fill="white"
    />
    <path
      d="M539.298 152.59C534.198 149.603 526.866 147.56 517.3 146.459C512.801 145.999 509.581 145.589 507.661 145.219C505.992 144.949 504.396 144.34 502.972 143.429C502.381 143.013 501.907 142.453 501.594 141.802C501.28 141.151 501.139 140.43 501.182 139.709C501.187 138.569 501.505 137.453 502.101 136.481C502.697 135.509 503.548 134.72 504.562 134.198C507.274 132.704 510.347 131.988 513.441 132.128C516.86 131.981 520.244 132.87 523.149 134.679C524.305 135.372 525.286 136.323 526.014 137.457C526.743 138.592 527.199 139.879 527.349 141.219H545.807C545.674 137.857 544.736 134.575 543.071 131.651C541.405 128.728 539.062 126.247 536.238 124.418C530.319 120.377 522.536 118.311 512.891 118.217C507.471 118.113 502.086 119.096 497.052 121.107C492.792 122.781 489.091 125.624 486.374 129.308C483.845 132.864 482.517 137.135 482.584 141.499C482.584 146.086 483.827 149.753 486.314 152.5C489.001 155.388 492.428 157.484 496.223 158.56C501.315 160.046 506.523 161.107 511.791 161.731C516.38 162.281 519.68 162.731 521.71 163.171C523.408 163.451 525.013 164.137 526.389 165.171C526.959 165.689 527.404 166.33 527.69 167.046C527.976 167.761 528.095 168.533 528.039 169.301C528.053 170.492 527.726 171.663 527.097 172.674C526.468 173.686 525.564 174.497 524.489 175.012C522.096 176.345 518.837 177.012 514.71 177.012C510.959 177.17 507.251 176.156 504.102 174.112C502.816 173.281 501.739 172.167 500.952 170.854C500.165 169.542 499.689 168.066 499.562 166.541H481.104C481.192 170.08 482.125 173.546 483.827 176.65C485.528 179.754 487.948 182.405 490.883 184.382C497.029 188.743 505.028 190.923 514.88 190.923C520.583 191.032 526.256 190.075 531.609 188.103C536.041 186.501 539.95 183.713 542.907 180.042C545.59 176.589 547.015 172.324 546.947 167.951C546.947 160.691 544.397 155.57 539.298 152.59Z"
      fill="white"
    />
    <path
      d="M625.448 159.13C625.838 156.949 626.022 154.736 625.998 152.52C626.063 146.201 624.422 139.982 621.249 134.519C618.181 129.447 613.74 125.349 608.44 122.698C602.554 119.802 596.06 118.362 589.502 118.497C582.995 118.372 576.566 119.925 570.834 123.008C565.521 125.936 561.17 130.34 558.306 135.689C555.208 141.609 553.667 148.22 553.826 154.9C553.677 161.555 555.269 168.132 558.446 173.982C561.487 179.414 566.093 183.805 571.664 186.583C577.404 189.523 584.219 190.993 592.112 190.993C597.656 191.1 603.157 189.994 608.23 187.753C612.68 185.785 616.565 182.731 619.529 178.872C622.273 175.261 624.096 171.035 624.838 166.561H605.66C604.93 169.495 603.12 172.045 600.591 173.702C597.627 175.494 594.203 176.381 590.742 176.252C585.783 176.252 581.88 174.782 579.033 171.841C576.187 168.901 574.52 164.861 574.034 159.72V159.17L625.448 159.13ZM579.653 136.609C582.656 134.155 586.447 132.876 590.322 133.008C594.287 132.85 598.181 134.093 601.321 136.519C604.31 139.044 606.209 142.627 606.62 146.519H574.524C574.896 142.679 576.733 139.13 579.653 136.609V136.609Z"
      fill="white"
    />
  </svg>
)

export default universe
