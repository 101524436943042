import { css } from "@emotion/core"
import {
  COLOR_2,
  BREAKPOINT_MEDIUM,
  BREAKPOINT_LARGE,
  NAV_GUTTERS_MOBILE,
  NAV_WIDTH_MEDIUM,
  NAV_WIDTH_LARGE,
} from "./styleConstants"
import sharedStyles from "./sharedStyles"

const nav = css`
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${COLOR_2};
  transform: translateX(100%);
  transition: transform 300ms ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  @media (min-width: ${BREAKPOINT_MEDIUM}px) {
    transform: translateX(100%);
    transition: none;
    left: auto;
    right: ${NAV_WIDTH_MEDIUM}px;
    width: ${NAV_WIDTH_MEDIUM}px;
  }
  @media (min-width: ${BREAKPOINT_LARGE}px) {
    right: ${NAV_WIDTH_LARGE}px;
    width: ${NAV_WIDTH_LARGE}px;
  }
`

const navScroll = css`
  padding: ${62 - NAV_GUTTERS_MOBILE / 2}px ${NAV_GUTTERS_MOBILE / 2}px ${NAV_GUTTERS_MOBILE}px
    ${NAV_GUTTERS_MOBILE / 2}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
  }
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  @media (min-width: ${BREAKPOINT_MEDIUM}px) {
    transition: none;
    padding: 24px 24px 80px 24px;
  }
`

const nav_open = css`
  ${nav}
  transition: transform 300ms ease;
  transform: translateX(0%);
`

const navTopLogo = css`
  position: fixed;
  left: 30px;
  top: 14px;
  z-index: 3;
  color: white;
  @media (min-width: ${BREAKPOINT_MEDIUM}px) {
    display: none;
  }
`

const navHeader = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: 2;
  background-color: ${COLOR_2};
  @media (min-width: ${BREAKPOINT_MEDIUM}px) {
    display: none;
  }
`

const navFooter = css`
  display: none;
  @media (min-width: ${BREAKPOINT_MEDIUM}px) {
    display: flex;
    padding: 25px;
    justify-content: center;
    align-items: flex-end;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(to top, ${COLOR_2} 60px, rgba(25, 25, 25, 0));
  }
  @media (min-width: ${BREAKPOINT_MEDIUM}px) {
    padding: 32px;
    display: flex;
    justify-content: space-between;
  }
`

const appleAppStoreButtonMobile = css`
  height: 50px;
`

const appleAppStoreButtonDesktop = css`
  height: 40px;
  @media (min-width: ${BREAKPOINT_MEDIUM}px) {
    height: 38px;
  }
  @media (min-width: ${BREAKPOINT_LARGE}px) {
    height: 40px;
  }
`

const bottomOfScroll = css`
  width: 100%;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${sharedStyles.hiddenOnDesktop}
`

const universeLogo = css`
  display: none;
  svg {
    width: 126px;
  }
  @media (min-width: ${BREAKPOINT_MEDIUM}px) {
    display: block;
    svg {
      width: 120px;
    }
  }
  @media (min-width: ${BREAKPOINT_LARGE}px) {
    svg {
      width: 126px;
    }
  }
`

export default {
  nav,
  nav_open,
  navScroll,
  navHeader,
  navFooter,
  navTopLogo,
  appleAppStoreButtonDesktop,
  appleAppStoreButtonMobile,
  bottomOfScroll,
  universeLogo,
}
