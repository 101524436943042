//global
export const COLOR_1 = "#000000"
export const COLOR_2 = "#191919"
export const FONT_COLOR_1 = "#FFFFFF"
export const FONT_COLOR_2 = "#B3B3B3"
export const BREAKPOINT_MOBILE = 500
export const BREAKPOINT_NAV_COLUMNS = 650
export const BREAKPOINT_MEDIUM = 1000
export const BREAKPOINT_SITE_GRID_COLUMNS = 1200
export const BREAKPOINT_LARGE = 1400

//nav
export const NAV_GUTTERS_MOBILE = 24
export const NAV_GUTTERS_DESKTOP = 16
export const NAV_WIDTH_MEDIUM = 320
export const NAV_WIDTH_LARGE = 450
