/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"

const design = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.4758 23.7066L25.3753 23.7642V31.8002H0.200012V6.62491H8.23601L8.29365 6.52442C9.25585 4.84706 10.5916 3.41393 12.1972 2.33624C13.8028 1.25854 15.6351 0.565264 17.552 0.310188C19.4688 0.0551112 21.4187 0.245097 23.2502 0.865405C25.0818 1.48571 26.7458 2.51966 28.1132 3.88703C29.4805 5.2544 30.5145 6.91842 31.1348 8.74998C31.7551 10.5815 31.9451 12.5314 31.69 14.4482C31.4349 16.3651 30.7417 18.1974 29.664 19.803C28.5863 21.4086 27.1531 22.7444 25.4758 23.7066ZM2.99692 9.42182V29.0033H22.5784V24.9328L22.3286 24.9971C20.2096 25.5419 17.9852 25.5249 15.8747 24.9479C13.7642 24.3709 11.8406 23.2538 10.2935 21.7067C8.74642 20.1596 7.6293 18.236 7.0523 16.1255C6.4753 14.015 6.45835 11.7906 7.00313 9.67162L7.06736 9.42182H2.99692ZM24.6209 4.67803C23.0108 3.60221 21.1179 3.028 19.1815 3.028C16.5848 3.028 14.0945 4.05952 12.2584 5.89564C10.4223 7.73175 9.39075 10.2221 9.39075 12.8187C9.39075 14.7552 9.96496 16.6481 11.0408 18.2582C12.1166 19.8682 13.6457 21.1231 15.4347 21.8642C17.2237 22.6052 19.1923 22.7991 21.0916 22.4213C22.9908 22.0436 24.7353 21.1111 26.1046 19.7418C27.4738 18.3726 28.4063 16.628 28.7841 14.7288C29.1619 12.8296 28.968 10.861 28.2269 9.07198C27.4859 7.28296 26.231 5.75385 24.6209 4.67803Z"
      fill="black"
    />
  </svg>
)

export default design
