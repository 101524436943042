/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"

const stores = (
  <svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.8629 21.3333H13.4278L9.68838 7.61905H31.6023L27.8629 21.3333ZM34.8099 5.17029C34.5204 4.7939 34.0724 4.57143 33.5985 4.57143H8.85638L7.91619 1.12305C7.73486 0.46019 7.13295 0 6.44571 0H1.52381C0.681143 0 0 0.681143 0 1.52381C0 2.36648 0.681143 3.04762 1.52381 3.04762H5.28305L6.22324 6.496L10.7947 23.2579C10.976 23.9208 11.5764 24.381 12.2651 24.381H29.027C29.7143 24.381 30.3147 23.9208 30.4975 23.2579L35.069 6.496C35.1939 6.03886 35.0979 5.54819 34.8099 5.17029V5.17029Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5502 25.9048C12.8695 25.9048 11.5026 27.2716 11.5026 28.9524C11.5026 30.6332 12.8695 32 14.5502 32C16.231 32 17.5979 30.6332 17.5979 28.9524C17.5979 27.2716 16.231 25.9048 14.5502 25.9048"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.7407 25.9048C25.06 25.9048 23.6931 27.2716 23.6931 28.9524C23.6931 30.6332 25.06 32 26.7407 32C28.4215 32 29.7884 30.6332 29.7884 28.9524C29.7884 27.2716 28.4215 25.9048 26.7407 25.9048"
      fill="black"
    />
  </svg>
)

export default stores
