import { css } from "@emotion/core"
import shareStyles from "./sharedStyles"
import { NAV_GUTTERS_MOBILE } from "./styleConstants"

const container = css`
  ${shareStyles.hiddenOnDesktop}
  top: ${NAV_GUTTERS_MOBILE - 2}px;
  right: ${NAV_GUTTERS_MOBILE - 2}px;
  position: fixed;
  z-index: 3;
`

export default {
  container,
}
