/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import hamburgerStyles from "../styles/hamburgerStyles"

interface IProps {
  navIsOpen: boolean
  onClick: () => void
}

const width = 24
const height = 12
const halfHeight = height / 2
const strokeWidth = 4
const halfStrokeWidth = strokeWidth / 2

const getTransformValue = (navIsOpen: boolean, defaultPos: number, rotateVal: number) =>
  `translate3d(0, ${navIsOpen ? halfHeight : defaultPos}px, 0) rotate(${
    navIsOpen ? `${rotateVal}deg` : "0"
  })`

const TwoBarMenuButton = (props: IProps) => {
  const { navIsOpen, onClick } = props

  const containerStyles = css`
    width: ${width}px;
    height: ${height}px;
    transform: rotate(0deg);
    flex-shrink: 0;
    ${hamburgerStyles.container}
  `

  const baseLineStyles = css`
    display: block;
    height: ${strokeWidth}px;
    width: 100%;
    background: white;
    border-radius: 100px;
    transform-origin: center;
    position: absolute;
    transition: transform 200ms ease;
  `

  const firstLine = css`
    transform: ${getTransformValue(navIsOpen, 0, 45)};
    margin-top: -${halfStrokeWidth}px;
  `

  const thirdLine = css`
    transform: ${getTransformValue(navIsOpen, height, -45)};
    margin-top: -${halfStrokeWidth}px;
  `

  return (
    <div css={containerStyles} onClick={onClick}>
      <span css={[baseLineStyles, firstLine]}></span>
      <span css={[baseLineStyles, thirdLine]}></span>
    </div>
  )
}

export default TwoBarMenuButton
