/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"

const blogs = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.3333 29.3333C27.5387 29.3333 29.3333 27.5387 29.3333 25.3333V6.66667C29.3333 4.46133 27.5387 2.66667 25.3333 2.66667H6.66667C4.46133 2.66667 2.66667 4.46133 2.66667 6.66667V25.3333C2.66667 27.5387 4.46133 29.3333 6.66667 29.3333H25.3333ZM6.66667 0H25.3333C29.0093 0 32 2.99067 32 6.66667V25.3333C32 29.0093 29.0093 32 25.3333 32H6.66667C2.99067 32 0 29.0093 0 25.3333V6.66667C0 2.99067 2.99067 0 6.66667 0ZM14.6667 10.6667C14.6667 12.872 12.872 14.6667 10.6667 14.6667C8.46133 14.6667 6.66667 12.872 6.66667 10.6667C6.66667 8.46133 8.46133 6.66667 10.6667 6.66667C12.872 6.66667 14.6667 8.46133 14.6667 10.6667ZM17.5391 18.6667H24.0004C24.4764 18.6667 24.9164 18.4133 25.1551 18C25.3937 17.5867 25.3937 17.08 25.1551 16.6667L21.9244 11.072C21.4484 10.2467 20.0911 10.2467 19.6151 11.072L16.3844 16.6667C16.1457 17.08 16.1457 17.5867 16.3844 18C16.6231 18.4133 17.0631 18.6667 17.5391 18.6667ZM6.66667 22.6667H25.3333C26.0707 22.6667 26.6667 23.2627 26.6667 24C26.6667 24.7373 26.0707 25.3333 25.3333 25.3333H6.66667C5.92933 25.3333 5.33333 24.7373 5.33333 24C5.33333 23.2627 5.92933 22.6667 6.66667 22.6667Z"
      fill="black"
    />
  </svg>
)

export default blogs
