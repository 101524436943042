/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"

const blogsWhite = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7512 11.1721L21.7512 11.1722L24.9819 16.7669C25.1848 17.1183 25.1848 17.5487 24.9819 17.9002C24.779 18.2516 24.4051 18.4669 24.0004 18.4669H17.5391C17.1344 18.4669 16.7605 18.2516 16.5576 17.9002C16.3547 17.5487 16.3547 17.1183 16.5576 16.7669L19.7883 11.1722L19.7883 11.1721C19.9831 10.8343 20.3665 10.6532 20.7697 10.6532C21.173 10.6532 21.5564 10.8343 21.7512 11.1721ZM25.3333 29.5335C27.6491 29.5335 29.5333 27.6493 29.5333 25.3335V6.66686C29.5333 4.35107 27.6491 2.46686 25.3333 2.46686H6.66668C4.35089 2.46686 2.46668 4.35107 2.46668 6.66686V25.3335C2.46668 27.6493 4.35089 29.5335 6.66668 29.5335H25.3333ZM6.66668 0.200195H25.3333C28.8989 0.200195 31.8 3.10132 31.8 6.66686V25.3335C31.8 28.8991 28.8989 31.8002 25.3333 31.8002H6.66668C3.10114 31.8002 0.200012 28.8991 0.200012 25.3335V6.66686C0.200012 3.10132 3.10114 0.200195 6.66668 0.200195ZM14.4667 10.6669C14.4667 12.7617 12.7616 14.4669 10.6667 14.4669C8.5718 14.4669 6.86668 12.7617 6.86668 10.6669C6.86668 8.57199 8.5718 6.86686 10.6667 6.86686C12.7616 6.86686 14.4667 8.57199 14.4667 10.6669ZM6.66668 22.8669H25.3333C25.9602 22.8669 26.4667 23.3733 26.4667 24.0002C26.4667 24.6271 25.9602 25.1335 25.3333 25.1335H6.66668C6.0398 25.1335 5.53335 24.6271 5.53335 24.0002C5.53335 23.3733 6.0398 22.8669 6.66668 22.8669Z"
      fill="white"
    />
  </svg>
)

export default blogsWhite
