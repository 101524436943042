export type TagData = {
  title: string
  displayTitle: string
  description?: string
}

const fetchTags = async () => {
  return {
    json: (): TagData[] => {
      return [
        { displayTitle: "Commerce", title: "buyBlock" },
        { displayTitle: "Art", title: "art" },
        { displayTitle: "Business", title: "businesses" },
        { displayTitle: "Empower", title: "empower" },
        { displayTitle: "Music", title: "music" },
        { displayTitle: "Design", title: "design" },
        { displayTitle: "Photo", title: "photo" },
        { displayTitle: "Writing", title: "writing" },
        { displayTitle: "Blogs", title: "blogs" },
        { displayTitle: "Lifestyle", title: "lifestyle" },
        { displayTitle: "Random", title: "random" },
        { displayTitle: "Stores", title: "stores" },
        { displayTitle: "Fashion", title: "fashion" },
      ]
    },
  }
}

export { fetchTags }
