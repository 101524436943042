import ReactGA from "react-ga4"
import { MouseEvent } from "react"
import config from "../config"

const shouldSendEvents = config.environmentName === "production"

if (shouldSendEvents) {
  ReactGA.initialize("UA-53073906-4", {
    gtagOptions: {
      // By turning off automatic page views, we can use our function to send
      // manual ones
      send_page_view: false,
    },
  })
}

function sendPageView() {
  if (shouldSendEvents) {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search })
  }
}

function sendOutboundLinkClick(event: MouseEvent<HTMLAnchorElement>) {
  // this typecasting sucks - find another way of doing this
  if (shouldSendEvents) {
    const currentTarget = event.currentTarget as any as { href: string }
    ReactGA.event({
      category: "Outbound Link",
      action: "click",
      label: currentTarget.href,
      transport: "beacon",
    })
  }
}

export { sendPageView, sendOutboundLinkClick }
