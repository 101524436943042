/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"

const featured = (
  <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1245 10.2152L16.7636 4.80965L13.4027 10.2152C12.8175 11.1565 11.8877 11.832 10.8116 12.0977L4.63209 13.6237L8.73448 18.4905C9.44886 19.338 9.804 20.431 9.7242 21.5366L9.26593 27.8852L15.1623 25.4875C16.189 25.07 17.3383 25.07 18.365 25.4875L24.2614 27.8852L23.8031 21.5366C23.7233 20.431 24.0784 19.338 24.7928 18.4905L28.8952 13.6237L22.7157 12.0977C21.6396 11.832 20.7098 11.1565 20.1245 10.2152ZM26.1176 31.5423L17.3523 27.978C16.9749 27.8245 16.5524 27.8245 16.175 27.978L7.40967 31.5423C6.88603 31.7552 6.32079 31.3445 6.36149 30.7807L7.04273 21.343C7.07207 20.9366 6.94151 20.5348 6.6789 20.2233L0.580412 12.9884C0.216089 12.5562 0.431992 11.8917 0.980779 11.7562L10.1671 9.48769C10.5627 9.39 10.9045 9.14167 11.1196 8.79564L16.1158 0.759941C16.4143 0.279889 17.113 0.27989 17.4115 0.75994L22.4077 8.79564C22.6228 9.14167 22.9646 9.39 23.3602 9.48769L32.5465 11.7562C33.0953 11.8917 33.3112 12.5562 32.9469 12.9884L26.8484 20.2233C26.5858 20.5348 26.4552 20.9366 26.4846 21.343L27.1658 30.7807C27.2065 31.3445 26.6413 31.7552 26.1176 31.5423Z"
      fill="black"
    />
  </svg>
)

export default featured
