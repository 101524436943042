/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"

const designWhite = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.3763 23.5328C25.252 23.6041 25.1753 23.7364 25.1753 23.8797V31.5999H0.400024V6.82461H8.12018C8.26348 6.82461 8.39584 6.74795 8.46714 6.62365C9.41406 4.97293 10.7286 3.56258 12.3087 2.50201C13.8888 1.44144 15.692 0.759171 17.5784 0.508147C19.4648 0.257123 21.3836 0.444091 23.1861 1.05454C24.9885 1.665 26.6261 2.68252 27.9718 4.02816C29.3174 5.3738 30.3349 7.01138 30.9454 8.81384C31.5558 10.6163 31.7428 12.5352 31.4918 14.4216C31.2408 16.308 30.5585 18.1112 29.4979 19.6913C28.4373 21.2714 27.027 22.5859 25.3763 23.5328ZM2.79694 28.803C2.79694 29.0239 2.97602 29.203 3.19694 29.203H22.3784C22.5993 29.203 22.7784 29.0239 22.7784 28.803V25.1905C22.7784 25.0668 22.7212 24.9501 22.6235 24.8744C22.5258 24.7986 22.3986 24.7723 22.2788 24.8031C20.1935 25.3392 18.0044 25.3225 15.9275 24.7547C13.8505 24.1869 11.9575 23.0875 10.435 21.565C8.91244 20.0425 7.81306 18.1494 7.24523 16.0725C6.6774 13.9955 6.66072 11.8065 7.19685 9.72112C7.22763 9.60138 7.20131 9.47412 7.12555 9.37641C7.04978 9.2787 6.93309 9.22153 6.80944 9.22153H3.19694C2.97602 9.22153 2.79694 9.40061 2.79694 9.62153V28.803ZM24.732 4.51145C23.0891 3.41365 21.1575 2.8277 19.1815 2.8277C16.5318 2.8277 13.9906 3.8803 12.117 5.75392C10.2434 7.62755 9.19076 10.1687 9.19076 12.8184C9.19076 14.7944 9.7767 16.726 10.8745 18.369C11.9723 20.012 13.5326 21.2925 15.3582 22.0487C17.1838 22.8048 19.1926 23.0027 21.1306 22.6172C23.0686 22.2317 24.8488 21.2802 26.246 19.883C27.6432 18.4857 28.5948 16.7055 28.9803 14.7675C29.3657 12.8295 29.1679 10.8207 28.4117 8.99515C27.6556 7.16958 26.375 5.60924 24.732 4.51145Z"
      fill="white"
    />
  </svg>
)

export default designWhite
