import { css } from "@emotion/core"
import {
  BREAKPOINT_MEDIUM,
  BREAKPOINT_LARGE,
  NAV_WIDTH_MEDIUM,
  NAV_WIDTH_LARGE,
  FONT_COLOR_2,
  BREAKPOINT_SITE_GRID_COLUMNS,
} from "./styleConstants"

export default {
  page: css`
    display: flex;
    flex-direction: column;
    z-index: 1;
    position: relative;
    padding-bottom: 64px;
    @media (min-width: ${BREAKPOINT_MEDIUM}px) {
      margin-right: ${NAV_WIDTH_MEDIUM}px;
    }
    @media (min-width: ${BREAKPOINT_LARGE}px) {
      margin-right: ${NAV_WIDTH_LARGE}px;
    }
  `,
  title: css`
    font-family: "Aeonik-Medium", sans-serif;
    font-size: 46px;
    line-height: 55px;
    margin: 48px 24px 0px 24px;
    @media (min-width: ${BREAKPOINT_SITE_GRID_COLUMNS}px) {
      margin: 64px 64px 0px 64px;
    }
  `,
  subtitle: css`
    font-family: "Aeonik-Light", sans-serif;
    font-size: 18px;
    line-height: 22px;
    margin: 4px 24px 0px 24px;
    color: ${FONT_COLOR_2};
    @media (min-width: ${BREAKPOINT_SITE_GRID_COLUMNS}px) {
      margin: 4px 64px 0px 64px;
    }
    a {
      text-decoration: underline;
      color: ${FONT_COLOR_2};
    }
  `,
}
