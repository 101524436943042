import { css } from "@emotion/core"
import { FONT_COLOR_1, COLOR_1 } from "./styleConstants"

export const globalStyles = css`
  @font-face {
    font-family: "Aeonik-Light";
    src: url("/fonts/Aeonik-Light.woff") format("woff");
  }

  @font-face {
    font-family: "Aeonik-Regular";
    src: url("/fonts/Aeonik-Regular.woff") format("woff");
  }

  @font-face {
    font-family: "Aeonik-Medium";
    src: url("/fonts/Aeonik-Medium.woff") format("woff");
  }

  html {
    box-sizing: border-box;
    color: ${FONT_COLOR_1};
    font-family: Aeonik-Light, sans-serif;
    background-color: ${COLOR_1};
    overflow-y: scroll;
  }

  a {
    text-decoration: none;
    color: ${FONT_COLOR_1};
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  h1 {
    font-size: 46px;
    line-height: 55px;
  }
`
