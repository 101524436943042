import { css } from "@emotion/core"
import { BREAKPOINT_SITE_GRID_COLUMNS } from "./styleConstants"

export default {
  grid: css`
    padding: 36px 12px 0px 12px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;
    @media (min-width: ${BREAKPOINT_SITE_GRID_COLUMNS}px) {
      padding: 36px 52px 0px 52px;
    }
  `,
}
