/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"

const storesWhite = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 32">
    <path
      fill="#fff"
      d="M35.1 6.5l-.2-.1.2.1zm-.2-.1l-4.6 16.8c-.2.6-.7 1-1.3 1H12.3c-.6 0-1.1-.4-1.3-1L6.4 6.4 5.5 3c0-.1-.1-.1-.2-.1H1.5C.8 2.9.2 2.3.2 1.6.2.9.8.3 1.5.3h4.9c.6 0 1.1.4 1.3 1l.9 3.4c0 .1.1.1.2.1h24.7c.4 0 .8.2 1.1.5.3.3.4.7.3 1.1zm-3.1 1.3v-.2s-.1-.1-.2-.1H9.7c-.1 0-.1 0-.2.1 0 0-.1.1 0 .2l3.7 13.7c0 .1.1.1.2.1h14.4c.1 0 .2-.1.2-.1l3.8-13.7zM14.6 26.1c-1.6 0-2.8 1.3-2.8 2.8s1.3 2.8 2.8 2.8c1.6 0 2.8-1.3 2.8-2.8s-1.3-2.8-2.8-2.8zm12.1 0c-1.6 0-2.8 1.3-2.8 2.8s1.3 2.8 2.8 2.8a2.8 2.8 0 1 0 0-5.6z"
    />
  </svg>
)

export default storesWhite
