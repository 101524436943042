/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"

const randomWhite = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      fill="#fff"
      d="M16 8.5a7.49 7.49 0 1 0 0 15 7.49 7.49 0 1 0 0-15zm0 12.6c-2.8 0-5.1-2.3-5.1-5.1s2.3-5.1 5.1-5.1 5.1 2.3 5.1 5.1-2.3 5.1-5.1 5.1zM5.6 24.3l-.1.1.1-.1zm18.7 2l.1.2-.1-.2zm1.7 2.2l-.1-.2.1.2zm2.3-22.4C23.6.3 15.7-1.3 9.2 1.7H9c-1.3-1-3.1-1-4.5.1a3.6 3.6 0 0 0-1 4.3v.2c-1.8 2.3-2.9 5-3.2 8-.5 4.2.7 8.3 3.4 11.6 3.1 3.9 7.7 5.9 12.3 5.9 3.5 0 7-1.1 9.9-3.5 3.3-2.6 5.3-6.4 5.8-10.6.5-4.2-.7-8.3-3.4-11.6zm1 11.3c-.4 3.6-2.1 6.8-4.9 9-5.8 4.6-14.2 3.7-18.9-2-2.2-2.8-3.3-6.3-2.9-9.9.3-2.4 1.2-4.6 2.6-6.5.1-.1.2-.1.2-.1 1.2.5 2.6.4 3.7-.5s1.5-2.2 1.3-3.5c0-.1 0-.2.1-.2 1.8-.7 3.6-1.1 5.5-1.1 3.9 0 7.8 1.7 10.5 5 2.2 2.8 3.2 6.3 2.8 9.8z"
    />
  </svg>
)

export default randomWhite
