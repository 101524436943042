import { css } from "@emotion/core"
import { BREAKPOINT_MOBILE, BREAKPOINT_SITE_GRID_COLUMNS } from "./styleConstants"

const SITE_CARD_ASPECT_RATIO = 4 / 3
const BORDER_RADIUS = "8%/6%"

export default {
  card: css`
    box-shadow: 0px 0px 24px rgba(53, 53, 53, 0.3);
    flex: 0 1 calc(50% - 24px);
    padding-top: calc(${50 * SITE_CARD_ASPECT_RATIO}% - 24px);
    margin: 12px;
    border-radius: ${BORDER_RADIUS};
    overflow: hidden;
    object-fit: fill;
    position: relative;
    @media (min-width: ${BREAKPOINT_MOBILE}px) {
      flex: 0 1 calc(25% - 24px);
      padding-top: calc(${25 * SITE_CARD_ASPECT_RATIO}% - 24px);
    }
    @media (min-width: ${BREAKPOINT_SITE_GRID_COLUMNS}px) {
      margin: 16px;
      flex: 0 1 calc(25% - 32px);
      padding-top: calc(${25 * SITE_CARD_ASPECT_RATIO}% - 32px);
    }
    overflow-x: hidden;
    overflow-y: hidden;
    background-size: cover;
    img {
      border-radius: ${BORDER_RADIUS};
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
    }
  `,
  noImage: css`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: FONT_COLOR_1;
  `,
  noImageInner: css`
    display: flex;
    flex-direction: column;
  `,
}
