/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"
import navCardStyles from "../styles/navCardStyles"
import { Link, Match } from "@reach/router"
import icons from "../images/icons/index"

interface IProps {
  tagDataTitle: string
  displayTitle: string
  path?: string
}

const NavCard = (props: IProps) => {
  const title = props.tagDataTitle === "buyBlock" ? "commerce" : props.tagDataTitle
  const path = props.path || `/${title}`
  return (
    <Match path={path}>
      {({ match }) => (
        <Link css={match ? navCardStyles.card_current : navCardStyles.card} to={path}>
          {icons[`${props.tagDataTitle}${!match ? "_white" : ""}`]}
          {props.displayTitle}
        </Link>
      )}
    </Match>
  )
}

export default NavCard
