/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core"

const photo = (
  <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8571 0C11.2438 0 10.6922 0.373426 10.4644 0.942914L9.18445 4.14286H4.2619C3.13158 4.14286 2.04755 4.59188 1.24828 5.39114C0.449021 6.1904 0 7.27444 0 8.40476V27.7381C0 28.8684 0.449021 29.9525 1.24828 30.7517C2.04754 31.551 3.13158 32 4.2619 32H29.119C30.2494 32 31.3334 31.551 32.1327 30.7517C32.9319 29.9525 33.381 28.8684 33.381 27.7381V8.40476C33.381 7.27443 32.9319 6.1904 32.1327 5.39114C31.3334 4.59188 30.2494 4.14286 29.119 4.14286H24.1965L22.9165 0.942914C22.6887 0.373426 22.1372 0 21.5238 0H11.8571ZM11.5927 6.19994L12.8727 3H20.5083L21.7882 6.19994C22.016 6.76943 22.5676 7.14286 23.181 7.14286H29.119C29.4537 7.14286 29.7747 7.27581 30.0114 7.51246C30.248 7.74911 30.381 8.07009 30.381 8.40476V27.7381C30.381 28.0728 30.248 28.3937 30.0114 28.6304C29.7747 28.8671 29.4537 29 29.119 29H4.2619C3.92723 29 3.60626 28.867 3.3696 28.6304C3.13295 28.3937 3 28.0728 3 27.7381V8.40476C3 8.07008 3.13295 7.74911 3.3696 7.51246C3.60626 7.27581 3.92723 7.14286 4.2619 7.14286H10.2C10.8134 7.14286 11.3649 6.76943 11.5927 6.19994ZM12.6666 16.6903C12.6666 14.468 14.4681 12.6665 16.6904 12.6665C18.9127 12.6665 20.7142 14.468 20.7142 16.6903C20.7142 18.9126 18.9127 20.7141 16.6904 20.7141C14.4681 20.7141 12.6666 18.9126 12.6666 16.6903ZM16.6904 9.6665C12.8113 9.6665 9.66663 12.8112 9.66663 16.6903C9.66663 20.5695 12.8113 23.7141 16.6904 23.7141C20.5696 23.7141 23.7142 20.5695 23.7142 16.6903C23.7142 12.8112 20.5696 9.6665 16.6904 9.6665Z"
      fill="black"
    />
  </svg>
)

export default photo
