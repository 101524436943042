import { css } from "@emotion/core"
import {
  COLOR_1,
  BREAKPOINT_NAV_COLUMNS,
  NAV_GUTTERS_MOBILE,
  BREAKPOINT_MEDIUM,
  BREAKPOINT_LARGE,
  NAV_WIDTH_MEDIUM,
  NAV_WIDTH_LARGE,
  NAV_GUTTERS_DESKTOP,
} from "./styleConstants"

const card = css`
  width: calc((50vw - ${NAV_GUTTERS_MOBILE / 2}px) - ${NAV_GUTTERS_MOBILE}px);
  height: calc((50vw - ${NAV_GUTTERS_MOBILE / 2}px) - ${NAV_GUTTERS_MOBILE}px);
  margin: ${NAV_GUTTERS_MOBILE / 2}px;
  font-size: 18px;
  svg {
    height: 32px;
    width: auto;
  }
  @media (min-width: ${BREAKPOINT_NAV_COLUMNS}px) {
    width: calc((33.333vw - 8px) - ${NAV_GUTTERS_MOBILE}px);
    height: calc((33.333vw - 8px) - ${NAV_GUTTERS_MOBILE}px);
  }
  @media (min-width: ${BREAKPOINT_MEDIUM}px) {
    width: ${(NAV_WIDTH_MEDIUM - 48) / 2 - NAV_GUTTERS_DESKTOP}px;
    height: ${(NAV_WIDTH_MEDIUM - 48) / 2 - NAV_GUTTERS_DESKTOP}px;
    margin: ${NAV_GUTTERS_DESKTOP / 2}px;
    svg {
      height: 24px;
      width: auto;
    }
  }
  @media (min-width: ${BREAKPOINT_LARGE}px) {
    width: ${(NAV_WIDTH_LARGE - 48) / 3 - NAV_GUTTERS_DESKTOP}px;
    height: ${(NAV_WIDTH_LARGE - 48) / 3 - NAV_GUTTERS_DESKTOP}px;
  }
  border-radius: 6px;
  flex: 0 0 auto;
  background-color: ${COLOR_1};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  font-family: "Aeonik-Regular", sans-serif;
  text-transform: capitalize;
`

const card_current = css`
  ${card}
  background-color: white;
  color: ${COLOR_1};
`

export default {
  card,
  card_current,
}
