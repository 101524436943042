import { css } from "@emotion/core"
import { BREAKPOINT_MEDIUM } from "./styleConstants"

export default {
  hiddenOnDesktop: css`
    @media (min-width: ${BREAKPOINT_MEDIUM}px) {
      display: none;
    }
  `,
}
